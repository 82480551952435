import React from 'react'
import './preview.css'
import { DownloadIcon } from '@heroicons/react/solid'
import { Auth } from '../core/auth'
import path from 'path'
import { encode } from 'base64-arraybuffer'
import fileDownload from 'js-file-download'
import { throws } from 'assert'
import { css } from '@emotion/react'
import BarLoader from 'react-spinners/PulseLoader'

export class Preview extends React.Component<
  { url: string; timestamp: string; usePreview: boolean; type: string },
  { isLoaded: boolean; preview: any; isDownloading: boolean; lastUrl: string }
> {
  constructor(props: any) {
    super(props)

    this.state = {
      isLoaded: false,
      preview: Buffer.from([]),
      isDownloading: false,
      lastUrl: '',
    }
  }

  componentDidMount = () => {
    this.fetchPreview()
  }

  getDownloadButton = () => {
    if (this.state.isDownloading === true) {
      return <div className="loader"></div>
    }

    return (
      <button className="p-1 m-1 rounded-lg">
        <DownloadIcon
          className="fill-current h-5 w-5 text-gray-600 mx-auto hover:text-green-500"
          onClick={this.onClickDownload}
        ></DownloadIcon>
      </button>
    )
  }

  onClickDownload = async () => {
    const token = await Auth.get().getJWT()
    const tokenString: string = token ?? ''
    const headers = { Authorization: tokenString }

    const fileName = path.basename(this.props.url)

    console.log(fileName)

    this.setState({ isDownloading: true })

    fetch(
      `http://service.imerza.com:6547/get/file?type=${this.props.type}&filename=${fileName}`,
      { headers }
    )
      .then((res) => res.json())
      .then((json) => {
        const blob = new Blob([new Uint8Array(json.Body.data)], {
          type: 'video/mp4',
        })

        fileDownload(blob, fileName)

        this.setState({ isDownloading: false })
      })
      .catch((e) => console.log(e))
  }

  fetchPreview = async () => {
    const token = await Auth.get().getJWT()
    const tokenString: string = token ?? ''
    const headers = { Authorization: tokenString }

    const fileName = path.basename(this.props.url)

    if (this.props.usePreview) {
      const adjustedFileName = fileName.replace('.mp4', '.jpg')
      fetch(
        `http://service.imerza.com:6547/get/previewImage?type=previews&filename=${adjustedFileName}`,
        { headers }
      )
        .then((res) => res.json())
        .then(
          (result) => {
            if (result.msg === undefined) {
              this.setState({ isLoaded: true, preview: result })
            }
          },
          (error) => {
            this.setState({
              isLoaded: true,
            })
          }
        )
    } else {
      fetch(
        `http://service.imerza.com:6547/get/previewImage?type=${this.props.type}&filename=${fileName}`,
        { headers }
      )
        .then((res) => res.json())
        .then(
          (result) => {
            if (result.msg === undefined) {
              this.setState({ isLoaded: true, preview: result })
            }
          },
          (error) => {
            this.setState({
              isLoaded: true,
            })
          }
        )
    }
  }

  render = () => {
    if (this.state.isLoaded) {
      if (this.state.preview.Body === undefined) {
        console.log(this.props)
        console.log(this.state.preview)
        return <div></div>
      }
      const base64String = encode(this.state.preview.Body.data)
      const src: string = 'data:image/png;base64, ' + base64String
      return (
        <div className="preview bg-gray-100 rounded-sm shadow-lg">
          <img
            src={src}
            className="self-center justify-self-center mx-auto  object-cover h-48 w-full "
          ></img>
          <div>
            <div className="grid grid-flow-col auto-cols-max justify-between">
              <p className="p-3">{this.props.timestamp}</p>
              {this.getDownloadButton()}
            </div>
          </div>
        </div>
      )
    }

    return (
      <div className="preview bg-gray-100 rounded-sm shadow-lg">
        <div className="loading">
          <div className="m-auto">
            <BarLoader size="6" color="rgba(16, 185, 129)" />
          </div>
        </div>
        <div className="">
          <div className="grid grid-flow-col auto-cols-max justify-between">
            <p className="p-3">{this.props.timestamp}</p>
            {this.getDownloadButton()}
          </div>
        </div>
      </div>
    )
  }
}
