import React from 'react'
import '../tailwind.css'
import { LockClosedIcon } from '@heroicons/react/solid'
import { Auth } from '../core/auth'
import { withRouter } from 'react-router'

class Login extends React.Component<
  { history: any; match: any; location: any },
  { email: string; password: string }
> {
  constructor(props: any) {
    super(props)

    this.state = { email: '', password: '' }
    this.setEmail = this.setEmail.bind(this)
  }

  getLoginButton = () => {
    return (
      <button
        type="submit"
        onClick={(e: any) => {
          Auth.get().auth(
            this.state.email,
            this.state.password,
            (result: any) => {
              if (result.success !== true) {
                // Toast!
              }
            }
          )
        }}
        className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        <span className="absolute left-0 inset-y-0 flex items-center pl-3">
          <LockClosedIcon
            className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400"
            aria-hidden="true"
          />
        </span>
        Sign in
      </button>
    )
  }

  setEmail = (e: any) => {
    this.setState({ email: e.target.value })
  }

  setPassword = (e: any) => {
    this.setState({ password: e.target.value })
  }

  render() {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8">
          <div>
            <img
              className="mx-auto h-12 w-auto"
              src="https://static1.squarespace.com/static/5bc8e273aadd34251d0af6cf/t/5c2cee25562fa795d8a44a8b/1617885146508/?format=1500w"
              alt="Workflow"
            />
            <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
              Sign in to your account
            </h2>
          </div>

          <input type="hidden" name="remember" defaultValue="true" />
          <div className="rounded-md shadow-sm -space-y-px">
            <div>
              <label htmlFor="email-address" className="sr-only">
                Email
              </label>
              <input
                id="email"
                name="email"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                placeholder="Email-Address"
                value={this.state.email}
                onChange={this.setEmail}
              />
            </div>
            <div>
              <label htmlFor="password" className="sr-only">
                Password
              </label>
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                placeholder="Password"
                value={this.state.password}
                onChange={this.setPassword}
              />
            </div>
          </div>
          <div>{this.getLoginButton()}</div>
        </div>
      </div>
    )
  }
}

export default withRouter(Login)
