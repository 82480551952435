import firebase from 'firebase/app'
import 'firebase/auth'
import * as Router from 'react-router'

const firebaseConfig = {
  apiKey: 'AIzaSyCOh23pvNtzfgl4NhLFuXu1MsiQklyHt_I',
  authDomain: 'imerza-290b2.firebaseapp.com',
  databaseURL: 'https://imerza-290b2.firebaseio.com',
  projectId: 'imerza-290b2',
  storageBucket: 'imerza-290b2.appspot.com',
  messagingSenderId: '393796440953',
  appId: '1:393796440953:web:41ac3821a212ec94b195a8',
  measurementId: 'G-10HL5CZ3JL',
}

export class Auth {
  private static instance: Auth

  private constructor() {}
  static get = () => {
    if (!Auth.instance) {
      firebase.initializeApp(firebaseConfig)
      Auth.instance = new Auth()
    }
    return Auth.instance
  }

  isAuthed = () => {
    if (firebase.auth().currentUser != null) {
      return true
    }
    return false
  }

  auth = async (email: string, password: string, cb: Function) => {
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then((credential) => {
        cb({ success: true })
      })
      .catch((error) => {
        cb({ success: false, msg: error.message })
      })
  }

  logout = async () => {
    await firebase.auth().signOut()
  }

  getJWT = async () => {
    return await firebase.auth().currentUser?.getIdToken()
  }
}
