import '../../tailwind.css'
import React from 'react'
import { Preview } from '../../components/preview'
import { Auth } from '../../core/auth'
import { throws } from 'assert'
import { Paginate } from '../../components/pagination'

const resultsPerPage = 12

export class Videos extends React.Component<
  {},
  {
    files: Array<any>
    isLoaded: boolean
    numPages: number
    currentPage: number
  }
> {
  constructor(props: any) {
    super(props)
    this.state = { isLoaded: false, files: [], numPages: 1, currentPage: 0 }
  }

  getType = () => 'videos'
  usesPreview = () => true

  componentDidMount = async () => {
    const token = await Auth.get().getJWT()
    const tokenString: string = token ?? ''
    const headers = {
      Authorization: tokenString,
      'Access-Control-Allow-Origin': '*',
    }
    console.log(headers)
    fetch('http://service.imerza.com:6547/get/content?type=' + this.getType(), {
      headers,
    })
      .then((res) => res.json())
      .then(
        (result) => {
          console.log(result)
          this.setState({
            isLoaded: true,
            files: result.msg.Contents,
            numPages: result.msg.Contents.length / resultsPerPage,
          })
        },
        (error) => {
          this.setState({
            isLoaded: true,
          })
        }
      )
  }

  onPageChanged = (newPage: number) => {
    this.setState({ currentPage: newPage })
  }

  render = () => {
    if (this.state.isLoaded) {
      const resultsToRender = []

      const startIndex = this.state.currentPage * resultsPerPage
      const endIndex = startIndex + resultsPerPage
      for (let i = 0; i < this.state.files.length; i++) {
        if (i >= startIndex && i < endIndex) {
          console.log(this.state.files[i])
          resultsToRender.push(this.state.files[i])
        }
      }

      return (
        <div>
          <div className="grid grid-flow-row grid-cols-4 grid-rows-auto gap-4 ">
            {resultsToRender.map((val) => (
              <Preview
                url={val.Key}
                timestamp={val.LastModified}
                key={val.Key}
                usePreview={this.usesPreview()}
                type={this.getType()}
              />
            ))}
          </div>
          <div>
            <Paginate
              numPages={this.state.numPages}
              onChange={this.onPageChanged}
            />
          </div>
        </div>
      )
    }

    return (
      <div className="max-auto">
        <p className="justify-center ">Loading...</p>
      </div>
    )
  }
}
