import React from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom'

import { Auth } from './core/auth'
import Dashboard from './pages/dashboard'
import Login from './pages/login'

import firebase from 'firebase/app'
import 'firebase/auth'

class App extends React.Component<{}, {}> {
  constructor(props: any) {
    super(props)
    Auth.get()
    firebase.auth().onAuthStateChanged((usr: any) => {
      this.setState({})
    })
  }

  render = () => {
    if (Auth.get().isAuthed()) {
      return (
        <Router>
          <Switch>
            <Route path="/dashboard">
              <Dashboard />
            </Route>
            <Route path="/">
              <Redirect to="/dashboard"></Redirect>
            </Route>
            <Route path="/login">
              <Redirect to="/dashboard"></Redirect>
            </Route>
          </Switch>
        </Router>
      )
    } else {
      return (
        <Router>
          <Switch>
            <Route path="/login">
              <Login />
            </Route>
            <Route path="/">
              <Redirect to="/login"></Redirect>
            </Route>
          </Switch>
        </Router>
      )
    }
  }
}

export default App
