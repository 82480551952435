import React from 'react'

export class Paginate extends React.Component<
  { numPages: number; onChange: any },
  { currentPage: number }
> {
  constructor(props: any) {
    super(props)
    this.state = { currentPage: 0 }
  }

  goLeft = () => {
    this.props.onChange(this.state.currentPage - 1)
    this.setState({ currentPage: this.state.currentPage - 1 })
  }

  goRight = () => {
    this.props.onChange(this.state.currentPage + 1)
    this.setState({ currentPage: this.state.currentPage + 1 })
  }

  getLeft = () => {
    if (this.state.currentPage > 0) {
      return (
        <a
          className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
          onClick={this.goLeft}
        >
          <span className="sr-only">Previous</span>
          <svg
            className="h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fill-rule="evenodd"
              d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
              clip-rule="evenodd"
            />
          </svg>
        </a>
      )
    }
    return <div></div>
  }

  getRight = () => {
    if (this.state.currentPage < this.props.numPages - 1) {
      return (
        <a
          className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
          onClick={this.goRight}
        >
          <span className="sr-only">Next</span>

          <svg
            className="h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fill-rule="evenodd"
              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
              clip-rule="evenodd"
            />
          </svg>
        </a>
      )
    }
    return <div></div>
  }

  getPageNumber = () => {
    console.log(this.props.numPages)
    if (this.props.numPages < 1) {
      return <div></div>
    }

    return (
      <a
        href="#"
        aria-current="page"
        className="z-10 bg-indigo-50 border-indigo-500 text-indigo-600 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
      >
        {this.state.currentPage + 1}
      </a>
    )
  }

  render = () => {
    return (
      <div>
        <div className="px-4 py-3 flex items-center justify-between sm:px-6 ">
          <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
            <div>
              <nav
                className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px "
                aria-label="Pagination"
              >
                {this.getLeft()}
                {this.getPageNumber()}
                {this.getRight()}
              </nav>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
