import '../tailwind.css'
import { FilmIcon, PhotographIcon } from '@heroicons/react/solid'
import { LogoutIcon } from '@heroicons/react/outline'
import React from 'react'
import { Auth } from '../core/auth'
import { Redirect, Route, Switch, withRouter } from 'react-router'

import { Videos } from './dashboard/videos'

import imerzaLogo from '../imerza-diamond-alt.png'
import { Images } from './dashboard/images'

class Dashboard extends React.Component<
  { location: any; match: any; history: any },
  {}
> {
  constructor(props: any) {
    super(props)
  }

  logout = () => {
    Auth.get().logout()
  }

  showVideos = () => {
    this.props.history.push('/dashboard/videos')
  }

  showImages = () => {
    this.props.history.push('/dashboard/images')
  }

  handleNavVisuals = (route: string) => {
    if (this.props.location.pathname.endsWith(route)) {
      return 'p-4 bg-gray-200 text-black rounded-l-lg'
    }

    return 'p-4 text-gray-300'
  }

  render = () => (
    <div className="flex flex-row h-full bg-gray-200">
      <nav className="bg-gray-900 w-20  justify-between flex flex-col ">
        <div className="mt-10 mb-10">
          <a href="#">
            <img src={imerzaLogo} className="rounded-full p-2 mb-3 mx-auto " />
          </a>
          <div className="mt-10">
            <ul>
              <li className={this.handleNavVisuals('videos')}>
                <a>
                  <span>
                    <FilmIcon
                      className="t h-5 w-5 mx-auto hover:text-green-500"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      onClick={this.showVideos}
                    />
                  </span>
                </a>
              </li>
              <li className={this.handleNavVisuals('images')}>
                <a>
                  <span>
                    <PhotographIcon
                      className=" h-5 w-5 mx-auto hover:text-green-500"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      onClick={this.showImages}
                    />
                  </span>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="mb-4">
          <a>
            <span>
              <LogoutIcon
                className=" h-5 w-5 text-gray-300 mx-auto hover:text-green-500"
                viewBox="0 0 24 24"
                stroke="currentColor"
                onClick={this.logout}
              />
            </span>
          </a>
        </div>
      </nav>
      <div className="h-screen w-screen p-3.5">
        <Switch>
          <Route exact path="/dashboard">
            <Redirect to="/dashboard/images" />
          </Route>
          <Route path="/dashboard/videos">
            <Videos />
          </Route>
          <Route path="/dashboard/images">
            <Images />
          </Route>
        </Switch>
      </div>
    </div>
  )
}

export default withRouter(Dashboard)
